import { useSpringValue } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { useEffect, useState, useCallback, useRef } from 'react';

import { isInInputElement } from '../lib/isInElement';

function baseCheckDisabled(axis, target, root) {
    if (target === root || target === null) {
        return false;
    }
    const classList = target.classList || null;
    if (
        isInInputElement(target) ||
        (classList !== null && target.classList.contains('drag-disabled')) ||
        (classList !== null && target.classList.contains(`drag-${axis}-disabled`))
    ) {
        return true;
    }
    return baseCheckDisabled(axis, target.parentNode, root);
}

function useDragProgress({
    progress: wantedProgress,
    onTap = null,
    disabled = false,
    dragDisabled = false,
    computeProgress = null,
    onProgress = null,
    springParams = undefined,
    checkDisabled = baseCheckDisabled,
    threshold = 0,
    dragOptions = {
        filterTaps: true,
        axis: 'x',
        // preventScroll: 50,
        // preventScrollAxis: 'x',
    },
} = {}) {
    const refDragging = useRef(false);
    const refProgress = useRef(wantedProgress);
    const [dragging, setDragging] = useState(false);
    // const spring = useCallback(
    //     () => ({
    //         progress: wantedProgress,
    //         immediate: dragging || disabled,
    //         ...springParams,
    //     }),
    //     [wantedProgress, disabled],
    // );
    // const [{ progress }, api] = useSpring(spring);
    const progress = useSpringValue(wantedProgress, {
        immediate: dragging || disabled,
        ...springParams,
    });
    const onDrag = useCallback(
        (gestureState) => {
            const {
                active,
                tap,
                canceled,
                cancel: cancelGesture,
                event,
                axis,
                first,
            } = gestureState;
            if (canceled || checkDisabled(axis, event.target, event.currentTarget)) {
                cancelGesture();
                return;
            }

            if (disabled) {
                return;
            }

            if (tap) {
                if (onTap !== null) onTap(gestureState);
                return;
            }

            if (dragDisabled) {
                return;
            }

            const newProgress = computeProgress(gestureState);
            refDragging.current = active;
            refProgress.current = newProgress;
            if (active && first) {
                setDragging(true);
            } else if (!active) {
                setDragging(false);
            }
            progress.start(newProgress, {
                immediate: active,
            });
            if (onProgress !== null) {
                onProgress(newProgress, gestureState);
            }
        },
        [setDragging, disabled, dragDisabled, onTap, computeProgress, onProgress],
    );

    const bind = useDrag(onDrag, {
        threshold,
        ...dragOptions,
    });

    useEffect(() => {
        if (!refDragging.current && wantedProgress !== refProgress.current) {
            refProgress.current = wantedProgress;
            progress.start(wantedProgress, {
                immediate: false,
            });
        }
    }, [wantedProgress]);

    return {
        bind,
        dragging,
        progress,
    };
}

export default useDragProgress;
